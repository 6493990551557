import {ResponseCode} from "../utils/ResponseCode";

export const PlayerConstants = {
    PROCESS_PATH: "Induct_Scanner",
    VERSION: "V1.0.142.0"
};

export const mapErrorToPlayerEnum = (responseCode) => {
    switch (responseCode) {
        case ResponseCode.UNKNOWN_ERROR:
            return window.DolphinPlayer.Induct.PackageScanErrorType.UNKNOWN;
        case ResponseCode.AT_WRONG_STATION:
            return window.DolphinPlayer.Induct.PackageScanErrorType.WRONG_STATION;
        case ResponseCode.SHIPMENT_IN_ALERT:
            return window.DolphinPlayer.Induct.PackageScanErrorType.ALERT;
        case ResponseCode.READY_FOR_FC_RETURN:
            return window.DolphinPlayer.Induct.PackageScanErrorType.FC_RETURN;
        case ResponseCode.SIDELINE:
            return window.DolphinPlayer.Induct.PackageScanErrorType.SIDELINE;
        case ResponseCode.INVALID_MCO_CYCLE:
            return window.DolphinPlayer.Induct.PackageScanErrorType.INVALID_CYCLE;
        case ResponseCode.INVALID_MCO_XPT_PARENT_CYCLE:
            return window.DolphinPlayer.Induct.PackageScanErrorType.INVALID_XPT_PARENT_CYCLE;
        case ResponseCode.MRC_REFRESH_FAILED:
            return window.DolphinPlayer.Induct.PackageScanErrorType.MRC_REFRESH_FAILED;
        case ResponseCode.PACKAGE_OUTSIDE_JURISDICTION:
            return window.DolphinPlayer.Induct.PackageScanErrorType.OUTSIDE_JURISDICTION;
        case ResponseCode.MISSING_CONTAINER_PLAN:
            return window.DolphinPlayer.Induct.PackageScanErrorType.MISSING_CONTAINER_PLAN;
        case ResponseCode.SCHEDULED_FOR_FUTURE:
            return window.DolphinPlayer.Induct.PackageScanErrorType.FUTURE_DELIVERY;
        case ResponseCode.INVALID_TRACKING_ID:
            return window.DolphinPlayer.Induct.PackageScanErrorType.INVALID_BARCODE;
        case "NO_INTERNET":
            return window.DolphinPlayer.Induct.PackageScanErrorType.CONNECTION_ISSUE;
        default:
            return window.DolphinPlayer.Induct.PackageScanErrorType.INELIGIBLE_FOR_INDUCT;
    }
};
