export const ApplicationActions = {
    TASK_SELECTION_INDUCT: "TASK_SELECTION_INDUCT",
    SCAN_INDUCT_LOCATION: "SCAN_INDUCT_LOCATION",
    SCAN_PACKAGE: "SCAN_PACKAGE",
    CHANGE_PRINTER: "CHANGE_PRINTER",
    SCAN_PRINTER: "SCAN_PRINTER",
    CANCEL_PRINTER: "CANCEL_PRINTER",
    HELP_SELECTED: "HELP_SELECTED",
    BACK_SELECTED: "BACK_SELECTED",
    HELP_OPTION_SELECTED: "HELP_OPTION_SELECTED",
    HELP_CLOSED: "HELP_CLOSED"
};
